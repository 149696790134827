//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Home',
  data() {
    return {
      scrollReveal: null,
    }
  },
  mounted() {
    
    this.scrollReveal = this.$scrollReveal()
    this.scrollReveal.reveal('.anel', {
      // 动画的时长
      duration: 600,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: 'bottom',
      // 回滚的时候是否再次触发动画
      reset: true,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: '200px',
      // 其他可用的动画效果
      opacity: 0,
    })
  },
  methods: {
    toDownload(url) {
      if (url && typeof url === 'string') {
        window.open(url)
      } else {
        this.$router.push('/download')
      }
    },
  },
}
